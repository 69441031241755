import {
    isHomepage,
    isPost,
    getSessionGuid,
    isGatedPdfExists,
    isContactFormPage,
    isAddressFormPage,
    isMobile
} from "./helpers.js";


class Api {

    constructor(options) {
        this.options = options
    }

    #getParams() {
        const options = this.options;
        const params = {
            post: {
                ...options.post,
                url: window.location.href
            },
            device: isMobile(),
        };

        if (options.memberId) {
            params.member = {id: options.memberId};
        } else {
            params.session = {guid: getSessionGuid()};
        }

        return params;
    }

    #isApiAllowed() {
        const allowedPages = [
            '/membership/',
            '/welcome-to-tangle/',
        ];
        const isAllowedPagePath = allowedPages.includes(window.location.pathname)
        const allowOutpostApi = typeof window.allowOutpostApi !== 'undefined'

        return !!(isPost(this.options) ||
                isHomepage()) ||
                isAllowedPagePath ||
                isGatedPdfExists() ||
                isContactFormPage() ||
                allowOutpostApi ||
                isAddressFormPage
            ;
    }

    #validate() {
        const options = this.options;
        return options.apiKey && options.apiDomain;
    }

    #getAPIDomain(path) {
        const domain = this.options.apiDomain.replace(/\/$/, "")

        // use dedicated CTA server only for initial API requests
        if (path === '') {
            if (domain.includes('.devoutpost.xyz')) {
                return 'https://cta.devoutpost.xyz'
            } else if (domain.includes('.outpost.pub')) {
                return 'https://cta.outpost.pub'
            }
        }
        return domain
    }

    request(path, params, callback, errorCallback) {
        if (!this.#validate()) {
            console.log('Outpost API key or Domain name is missing.');
            return;
        }
        if (!this.#isApiAllowed()) {
            console.log('Outpost API is not allowed on this page.');
            return;
        }

        const baseParams = this.#getParams();
        // merge baseParams with params
        const apiParams = Object.assign(params, baseParams);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(apiParams),
            headers: {
                "Content-Type": "application/json",
            },
        };

        // find needed APi domain
        const apiDomain = this.#getAPIDomain(path)
        const url = `${apiDomain}/plugin-api${path}/${this.options.apiKey}/${this.options.memberId}`;

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(response => callback(response))
            .catch(error => {
                console.log('[OUTPOST API ERROR]', error)
                if (errorCallback) {
                    errorCallback()
                }
            });
    }


}

export default Api
