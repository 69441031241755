import RenderBase from "./render-base.js";

class RenderAdCampaign extends RenderBase {

    constructor(options, site, data) {
        super(options, site, data);
    }

    #containerAutoplacement(containerId, item) {
        let minParagraphs = item.autoplacement.minParagraphs || 3;
        let paragraphs = this.getPostContentItems(item)
        const settings = item.autoplacement

        if (paragraphs.length < minParagraphs) return;

        const newElement = document.createElement('div');
        newElement.className = `outpost-pub-container`;
        newElement.setAttribute(containerId, '');

        this.#insertContainer(settings, paragraphs, newElement);
        this.#setupStatisticEvents(settings, newElement)
    }

    #insertContainer(settings, filteredParagraphs, newElement) {
        const paragraphValue = parseInt(settings.paragraph || settings.value)

        if (settings.direction === 'start') {
            settings.paragraph = Math.min(paragraphValue, filteredParagraphs.length);
        } else {
            settings.paragraph = Math.max(paragraphValue, filteredParagraphs.length + 1 - paragraphValue);
        }

        filteredParagraphs.forEach((p, idx) => {
            if ((settings.after === 'after' && paragraphValue === idx + 1) ||
                (settings.after !== 'after' && paragraphValue === idx + 1)) {
                if (settings.after === 'after') {
                    p.parentNode.insertBefore(newElement, p.nextSibling);
                } else {
                    p.parentNode.insertBefore(newElement, p);
                }
            }
        });
    }

    #setupStatisticEvents(settings, element) {
        // check if we need to setup additional listeners
        this.setupViewObserver(element, () => this.viewEventAction(settings))
        this.setupClickEvent(element, () => this.clickEventAction(settings))
    }

    viewEventAction(settings) {
        if (settings.plausibleViewEventName) {
            this.logPlausibleEvent(settings.plausibleViewEventName)
        }
    }

    clickEventAction(settings) {
        if (settings.plausibleClickEventName) {
            this.logPlausibleEvent(settings.plausibleClickEventName)
        }
    }

    render() {
        Object.keys(this.data).forEach(key => {
            const item = this.data[key];

            if (item.html && this.isCtaAllowed(item)) {
                const containerId = `data-${key}`
                const itemSelector = `[${containerId}]`;

                if (!document.querySelectorAll(itemSelector).length) {
                    this.#containerAutoplacement(containerId, item);
                }

                document.querySelectorAll(itemSelector).forEach(container => {
                    container.innerHTML = item.html;
                    container.style.visibility = 'visible';
                });

                this.loadAssets(key, item);
            }
        });
    }


}

export default RenderAdCampaign
