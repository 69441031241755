import IntegrationBase from "./base.js";
import MicroModal from "../modal/index.js";

class AlgoliaSearch extends IntegrationBase {

    #modalSelector = 'algolia-search-popup'
    #initFlag = false

    #initSearch() {
        const params = this.params

        const searchClient = algoliasearch(params.applicationId, params.searchApiKey);
        const search = instantsearch({
            indexName: params.indexName,
            searchClient,
        });

        search.addWidgets([
            instantsearch.widgets.searchBox({
                container: '#outpost-algolia-search-box',
            }),

            instantsearch.widgets.hits({
                container: '#outpost-algolia-hits',
                transformItems(items) {
                    return items.map((item) => ({
                        ...item,
                        tag: item.tags.length ? item.tags[0].name : null,
                        author: item.authors.length ? item.authors[0].name : null,
                    }));
                },
                templates: {
                    item: (hit, { html, components }) => {
                        return html`<article class="algolia-hit">
                                <div style="display: flex; flex-direction: row; gap: 1em;">
                                    <div style="max-width: 90px;">
                                        <a href="${hit.url}">
                                            <img src="${hit.image}" alt="${hit.name}" />
                                        </a>
                                    </div>
                                    <div>
                                        <h1 style="font-size: 20px; margin-bottom: 5px;">
                                            <a href="${hit.url}">
                                                ${components.Highlight({ attribute: 'title', highlightedTagName: 'mark', hit })}
                                            </a>
                                        </h1>
                                        <div>
                                            By <strong>${hit.author}</strong> in <strong>${hit.tag}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    ${components.Snippet({ attribute: 'excerpt', highlightedTagName: 'mark', hit })}
                                </div>
                            </article>`
                    }
                },
            })
        ]);

        search.start();
    }

    #attach() {
        const params = this.params

        let control = document.querySelector(params.displaySelector || '[data-ghost-search]');
        if (control) {
            // remove all previous handlers
            control.replaceWith(control.cloneNode(true));
            control = document.querySelector(params.displaySelector);

            control.onclick = () => {
                if (!this.#initFlag) {
                    this.#initSearch()
                    this.#initFlag = true;
                }
                this.show()
            }
        }
    }

    init() {
        this.#attach()
    }

    show() {
        MicroModal.show(this.#modalSelector, {});
    }

}

export default AlgoliaSearch
