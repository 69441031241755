import Render from "./render.js";
import Api from "./api.js";
import IntegrationAnglerAI from "../integrations/anglerai.js";
import AlgoliaSearch from "../integrations/algolia-search.js";
import {appendBody, loadCssUrls, loadJs, loadCss} from "./helpers.js";

class Outpost {
    constructor(options) {
        const defaults = {}
        this.options = options && typeof options === "object" ? this.#extendDefaults(defaults, options) : defaults;
        this.integrations = {};
    }

    #extendDefaults(defaults, properties) {
        Object.keys(properties).forEach(property => {
            if (properties.hasOwnProperty(property)) {
                defaults[property] = properties[property];
            }
        });
        return defaults;
    }

    #loadIntegrations(integrations, site) {
        Object.keys(integrations).forEach(name => {
            let integrationData = integrations[name]
            let integration = null

            if (name === 'anglerai') {
                integration = new IntegrationAnglerAI(this.options, integrationData.params)

            } else if (name === 'algolia') {
                integration = new AlgoliaSearch(this.options, integrationData.params, site)
            }

            if (integration) {
                if (integrationData.jsUrls) {
                    loadJs(integrationData.jsUrls)
                }
                if (integrationData.cssUrls) {
                    loadCssUrls(integrationData.cssUrls)
                }
                if (integrationData.css) {
                    loadCss(name + '-css', integrationData.css)
                }
                if (integrationData.body) {
                    appendBody(integrationData.body)
                }

                integration.init()
                this.integrations[name] = integration
            }
        });
    }

    init() {
        const api = new Api(this.options)
        api.request('', {}, (response) => {
            // render visual modules
            const render = new Render(this.options, response.site)
            render.render()

            // load integrations
            if (response.site.integrations) {
                this.#loadIntegrations(response.site.integrations, response.site.site);
            }

            // save member data
            if (response.site.member) {
                const memberEvent = new CustomEvent('outpost.memberLoaded', {
                    detail: response.site.member,
                    bubbles: true,
                    cancelable: true
                });
                dispatchEvent(memberEvent);
            }
        })
    }

    logEvent(eventName, eventData) {
        Object.keys(this.integrations).forEach(name => {
            const integration = this.integrations[name];
            if (typeof integration[eventName] === "function") {
                integration[eventName](eventData);
            }
        });
    }

}

export default Outpost
